export const errorCodeHandling = (response, apiInfo = [null, null]) => {

    if (response?.data?.message === 'jwt expired' || response?.data?.message === 'jwt malformed') {
        window.location.href = '/tokenExpired'
    }

    switch (response?.status) {
        case 200:
            return {
                statuscommonmessage: 'Success',
                status: response.status,
                success: true,
                data: response.data.body,
                message: response.data.message,
                bookmark: response.data?.body?.bookmark,
            };

        case 400:
            return {
                statuscommonmessage: 'Bad Request',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        case 401:
            return {
                statuscommonmessage: 'Unauthorized Access',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        case 403:
            return {
                statuscommonmessage: 'Forbidden',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        case 404:
            return {
                statuscommonmessage: 'Not Found',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        case 409:
            return {
                statuscommonmessage: 'Conflict',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        case 500:
            return {
                statuscommonmessage: 'Internal Server Error',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };

        default:
            return {
                statuscommonmessage: 'Unknown Error',
                status: response.status,
                success: false,
                data: response.data.body,
                message: response.data.message,
                apiInfo: apiInfo
            };
    }
}