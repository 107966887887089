export default {
  apiRootUrl: process.env.REACT_APP_API_ROOT_URL ?? "",
  apiRootKeyTenant: process.env.REACT_APP_API_ROOT_KEY_TENANT ?? "",
  apiRootKeyAdmin: process.env.REACT_APP_API_ROOT_KEY_ADMIN ?? "",
  apiRootKeyInvestor: process.env.REACT_APP_API_ROOT_KEY_INVESTOR ?? "",
  zerodhaKey: process.env.REACT_APP_ZERODHA_KEY ?? "",
  apiSecret: process.env.REACT_APP_API_SECRET ?? "",
  serviceType: process.env.REACT_APP_SERVICE_TYPE ?? "",
  tenentCode: process.env.REACT_APP_TENENT_CODE ?? "",
  digilockerUrl: process.env.REACT_APP_DIGILOCKER_URL ?? "",
  digilockerAPIUrl: process.env.REACT_APP_DIGILOCKER_API_URL ?? "",
  digilockerKycUrl: process.env.REACT_APP_DIGILOCKER_KYC_URL ?? "",
  digilockerReferenceId: process.env.REACT_APP_DIGILOCKER_REFERENCE_ID ?? "",
  digilockerTenantId: process.env.REACT_APP_DIGILOCKER_TENANTID ?? "",
  digilockerAccessKey: process.env.REACT_APP_DIGILOCKER_ACCESS_KEY ?? "",
  payuUrl: process.env.REACT_APP_PAYU_URL ?? "",
  zerodhaLoginUrl: process.env.REACT_APP_ZERODHA_LOGIN ?? "",
  angelLoginUrl: process.env.REACT_APP_ANGEL_LOGIN ?? "",
  ipConfigUrl: process.env.REACT_APP_IP_CONFIG ?? "",
  popUp_Config: process.env.REACT_APP_PopUp_Config ?? "true",
};
